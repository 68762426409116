<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>我的账户</span>
    </div>
    <div class="content">
      <div class="content-herder">
        <div
          :class="[
            'content-herder-item',
            { 'content-herder-item-active': changeType === 1 }
          ]"
          @click="changeChangeType(1)"
        >
          <div>收入</div>
          <span></span>
        </div>
        <div
          :class="[
            'content-herder-item',
            { 'content-herder-item-active': changeType === 2 }
          ]"
          @click="changeChangeType(2)"
        >
          <div>支出</div>
          <span></span>
        </div>
      </div>
      <div class="content-main" @scroll="handleScroll">
        <div class="content-item" v-for="(item, index) in list" :key="index">
          <div class="content-item-left">
            <img src="@/assets/image/myAccount3.png" />
            <div class="name-box">
              <div class="name">{{ item.category }}</div>
              <div class="description">{{ item.description }}</div>
              <div class="time">{{ item.dateline }}</div>
            </div>
          </div>
          <div class="content-item-right">
            {{ changeType === 1 ? "+" : "-" }} {{ item.quantity }} 金币
          </div>
        </div>
        <div class="isBottom" v-if="list.length > 0">
          {{ isBottom ? "我也是有底线的" : "触底刷新更多~" }}
        </div>
        <van-empty v-if="list.length === 0" description="暂无数据" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      changeType: 1,
      list: [],
      userid: null,
      page: 1,
      isBottom: false,
      flag: true
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    changeChangeType(type) {
      this.changeType = type;
      this.list = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      await this.$http
        .get(`/api/v1/Accounts/${this.userid}/Points`, {
          params: {
            id: this.userid,
            page: this.page,
            size: 20,
            changeType: this.changeType
          }
        })
        .then(res => {
          console.log("res", res);
          if (res.status === 200) {
            if (res.data.list && res.data.list.length !== 0) {
              this.list = this.list.concat(res.data.list);
              this.flag = true;
              this.page++;
            } else {
              this.flag = false;
              this.isBottom = true;
            }
            if (res.data.list.length < 20) {
              this.flag = false;
              this.isBottom = true;
            }
          }
        });
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        // 触发刷新操作
        console.log("触底刷新");
        if (this.flag) {
          this.flag = false;
          console.log("触底刷新");
          this.getList();
        }
      }
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #f8f8fa;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .content {
    min-height: calc(100vh - 40px);
    .content-herder {
      height: 50px;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      .content-herder-item {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #232323;
        font-size: 16px;
      }
      .content-herder-item-active {
        color: #ffa200;
        font-weight: bolder;
        position: relative;
        span {
          width: 14px;
          height: 2px;
          background: #ffa200;
          border-radius: 10px 10px 10px 10px;
          position: absolute;
          left: 50%;
          bottom: 2px;
          transform: translateX(-50%);
        }
      }
    }
    .content-main {
      height: calc(100vh - 130px);
      overflow: auto;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .content-item {
        margin-bottom: 10px;
        width: 100%;
        padding: 18px 10px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content-item-left {
          display: flex;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            margin-right: 16px;
            flex-shrink: 0;
          }
          .name-box {
            word-break: break-all;
            .name {
              color: #232323;
              font-size: 14px;
            }
            .time {
              color: #666;
              font-size: 12px;
              //   margin-top: 11px;
            }
            .description {
              color: #666;
              font-size: 12px;
              margin-top: 8px;
            }
          }
        }
        .content-item-right {
          color: #ffa200;
          font-size: 16px;
          font-weight: bolder;
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
    }
  }
}
.isBottom {
  text-align: center;
  margin: 20px 0;
}
</style>
